import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import * as React from "react";
import ProjectsPageLayout from "../../components/ProjectsPageLayout";
import WebsiteProject from "../../components/WebsiteProject";
import ogImage from "../../images/og/projects.jpg";

const ProjectsPage = ({ data }) => {
  return (
    <ProjectsPageLayout
      pageTitle="Matthew Goslett's Website Portfolio"
      metaDescription="Matthew Goslett's website portfolio includes the likes of Superbalist - South Africa's leading e-commerce fashion destination, and MasterStart - a leading EdTech company based in Cape Town, South Africa."
      og={{ image: ogImage }}
    >
      {data.allWebsiteProjectsJson.edges.map(({ node }, index) => {
        const content = node.content.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ));
        const image = getImage(node.image);

        return (
          <React.Fragment key={index}>
            <WebsiteProject
              name={node.name}
              image={image}
              url={node.url}
              content={content}
            />
            <hr className="my-4" />
          </React.Fragment>
        );
      })}
    </ProjectsPageLayout>
  );
};

export const query = graphql`
  query ProjectsQuery {
    allWebsiteProjectsJson {
      edges {
        node {
          name
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          url
          content
        }
      }
    }
  }
`;

export default ProjectsPage;
