import { Link } from "gatsby";
import * as React from "react";
import { Container, Nav } from "react-bootstrap";
import PageHeader from "./PageHeader";
import PageLayout, { PageLayoutProps } from "./PageLayout";

const ProjectsPageLayout: React.FC<PageLayoutProps> = (props) => {
  return (
    <PageLayout {...props}>
      <Container className="mt-4">
        <PageHeader header="My Projects" />
        <Nav variant="pills">
          <Nav.Item>
            <Nav.Link as={Link} to="/projects/" activeClassName="active">
              Websites
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              as={Link}
              to="/projects/open-source/"
              activeClassName="active"
            >
              Open Source Packages
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <hr />
        {props.children}
      </Container>
    </PageLayout>
  );
};

export default ProjectsPageLayout;
