import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import * as React from "react";

type Props = {
  name: string;
  image: IGatsbyImageData;
  url?: string | null;
  content: any;
};

const WebsiteProject: React.FC<Props> = ({ name, image, url, content }) => {
  return (
    <div className="row">
      <div className="col-md-4 mb-3 mb-md-0">
        <a href="#" className="d-block d-md-none">
          <h4>{name}</h4>
        </a>
        <div className="img-border">
          <GatsbyImage image={image} alt="" className="rounded" />
        </div>
      </div>
      <div className="col-md-8">
        <a href="#" className="d-none d-md-inline">
          <h4>{name}</h4>
        </a>
        {content}
        {url && (
          <a href={url} className="btn btn-sm btn-dark">
            View Website
          </a>
        )}
      </div>
    </div>
  );
};

export default WebsiteProject;
